import { type ReactNode } from 'react'
import { type IconType } from 'react-icons/lib'
import { PiXCircle } from 'react-icons/pi'
import { cn } from '#app/utils/misc'

type HeroProps = {
	title: string
	icon?: IconType | ReactNode
	description?: string
	className?: string
	children?: React.ReactNode
}

export default function Hero(props: HeroProps) {
	// destructure props
	const {
		icon: Icon = PiXCircle,
		title,
		description,
		children,
		className,
	} = props

	return (
		<div
			className={cn('flex h-full flex-1 items-center text-center', className)}
		>
			<div className="flex flex-1 flex-col justify-center">
				{typeof Icon === 'function' ? (
					<Icon className="mx-auto h-20 w-20 text-gray-400" />
				) : (
					<div className="mx-auto h-20 w-20 text-gray-400">{Icon}</div>
				)}
				<h3 className="mt-4 text-2xl font-semibold text-gray-900">{title}</h3>
				{description && (
					<p className="mt-1 text-lg text-gray-500">{description}</p>
				)}
				{children}
			</div>
		</div>
	)
}
